<script>
import { ArrowUpIcon, ArrowRightIcon, PaperclipIcon} from "vue-feather-icons";
import Navbar from "@/components/navbar-private";
import Footer from "@/components/footer";
import Vue from 'vue'
import duppla from '@/duppla'
import moment from "moment";

/**
 * Index-developer component
 */
export default {
  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
    ArrowRightIcon,
    PaperclipIcon
  },
  data() {
    return {
      uuid: this.$store.state.auth.tokenPayload.uuid,
      data: {},
      loadingMedicalRecord: false
    };
  },
  computed: {
    fields () {
      let fields = ''
      fields += '?fields%5B%5D=contact_email'
      fields += '&fields%5B%5D=payment_date'
      fields += '&fields%5B%5D=payment'
      fields += '&fields%5B%5D=stripe_currency'
      fields += '&fields%5B%5D=card_brand'
      fields += '&fields%5B%5D=card_last4'
      fields += '&fields%5B%5D=exp_month'
      fields += '&fields%5B%5D=exp_year'
      fields += '&fields%5B%5D=stripe_amount_total'
      fields += '&fields%5B%5D=stripe_currency'
      fields += '&fields%5B%5D=stripe_payment_method_type'
      fields += '&fields%5B%5D=receipt_url'
      return fields
    },
    cardBrand () {
      let obj = {}
      switch (this.data.card_brand) {
        case 'visa':
          obj.img = '/images/payments/payment/visaa.png'
          obj.bg = 'bg-dark'
          obj.textcard = 'text-light'
          obj.text = 'text-muted'
          break;
        case 'mastercard':
          obj.img = '/images/payments/payment/master.png'
          obj.bg = 'bg-dark'
          obj.textcard = 'text-light'
          obj.text = 'text-muted'
          break;
        case 'amex':
          obj.img = '/images/payments/payment/amex.png'
          obj.bg = 'bg-light'
          obj.textcard = 'text-dark'
          obj.text = 'text-muted'
          break;
        default:
          obj.img = ''
          obj.bg = 'bg-info'
          obj.textcard = 'text-light'
          obj.text = 'text-light'
          break;
      }
      return obj
    }
  },
  methods: {
    getMedicalRecord () {
      return new Promise((resolve, reject) => {
        if(!this.loadingMedicalRecord){
          this.loadingMedicalRecord = true
          duppla({ url: 'my-medical-record' + this.fields, method: 'GET', data: null })
            .then(response => {
              if(!response.data){
                throw new Error("Expecting one record")
              }
              this.data = response.data.fields
              resolve()
            })
            .catch(error => {
              reject(error)
            })
            .finally(() => {
              this.loadingMedicalRecord = false
            })
        }
      })
    }
  },
  created () {
    this.getMedicalRecord().then(() => {}).finally(() => {console.debug("promise resolved!")})
  },
  mounted() {
  }
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half-170 d-table w-100 home-wrapper overflow-hidden" style="padding-top: 70px; padding-bottom: 90px;">
      <div class="container mt-5">
        <div class="position-relative" style="z-index: 1">
          <div class="card rounded shadow-lg bg-white p-4">
            <h4 class="card-title mb-4">Información de Pago </h4>
            <hr/>
            <div class="row justify-content-center" v-if="loadingMedicalRecord">
              <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="Cargando..."></b-spinner>
            </div>
            <div class="row" v-if="!loadingMedicalRecord">
              <div class="col-lg-6 mt-4 pt-2 mt-sm-0 pt-sm-0" v-if="!data.payment">
                <div class="counter-box text-center" >
                  <div class="alert alert-warning alert-pills mt-4" role="alert">
                     <span class="alert-content h5"> Pendiente de Pago </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mt-4 pt-2 mt-sm-0 pt-sm-0" v-if="data.payment">
                <div class="counter-box text-center" >
                  <h1 class="mb-0 mt-3" v-if="!loadingMedicalRecord">
                    <span class="counter-value">{{formatCurrency(data.stripe_amount_total)}}</span> {{data.stripe_currency}}
                  </h1>
                  <div class="alert alert-success alert-pills mt-4" role="alert">
                    <span class="alert-content h5"> ¡Pago Exitoso! </span>
                  </div>
                  <p class="text-muted mb-0 mt-0">Pago recibido el {{formatDate(data.payment_date,'DD/MM/YYYY')}} a las {{formatDate(data.payment_date,'H:mm')}} hrs</p>
                  <p class="text-muted mb-0 mt-0">
                    <a target="_blank" :href="data.receipt_url"> Da click aqui para obtener tu recibo de pago.</a>
                  </p>
                </div>
              </div>
              <div class="col-lg-4 pt-2 mt-4 mt-lg-0" v-if="data.payment && data.stripe_payment_method_type === 'card'">
                <a href="javascript:void(0)">
                    <div class="card rounded shadow border-0" :class="cardBrand.bg">
                        <div class="card-body">
                            <img :src="cardBrand.img" height="60" class="text-right" alt="">
                            <div class="mt-4">
                                <h5 :class="cardBrand.textcard">•••• •••• •••• {{data.card_last4}}</h5>
                                <div class="d-flex justify-content-between">
                                    <p class="h6 mb-0" :class="cardBrand.text">XXXXX XXX XXX</p>
                                    <h6 class="mb-0" :class="cardBrand.text">Exp: <span :class="cardBrand.text">{{data.exp_month}}/{{data.exp_year.slice(-2)}}</span></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
              </div>
              <div class="col-lg-4 pt-2 mt-4 mt-lg-0" v-if="data.payment && data.stripe_payment_method_type === 'oxxo'">
                <a href="javascript:void(0)">
                    <div class="card rounded shadow border-0">
                        <div class="card-body">
                            <img src="/images/payments/payment/oxxo.svg" height="80" class="text-right" alt="Oxxo">
                            <div class="mt-4">
                                <h6 class="text-dark">Pago en efectivo realizado en Oxxo</h6>
                            </div>
                        </div>
                    </div>
                </a>
              </div>
            </div>


            <!--div class="card-body">
              <div class="row justify-content-center" v-if="loadingMedicalRecord">
                <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="Cargando..."></b-spinner>
              </div>
              <span v-if="!loadingMedicalRecord">
                <h4 class="card-title mb-4">Información de Pago </h4>
                <hr/>
                <div class="row">
                  <div class="col-md-4">
                    
                  </div>
                </div>
              </span>
            </div-->
          </div>

        </div>
      </div>
    </section>


    <Footer />
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>

<style>
</style>
